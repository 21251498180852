import React from "react"
import Layout from "@app-components/layout/"
import Grid from "@app-components/ui/grid/"
import { css } from "@emotion/core"
import Feature from "@app-components/ui/feature/"
import Background from "@app-components/utils/background"
import Navigation from "@app-components/ui/header/components/navigation"
import HeaderImage from "@page-components/products/cinema-hardware/media/header/"
import SpeedImage from "@page-components/products/cinema-hardware/media/speeds/"
import PlugAndPlayImage from "@page-components/products/cinema-hardware/media/plug-and-play/"
import IntuitiveDesignImage from "@page-components/products/cinema-hardware/media/intuitive-design/"
import VendorNuetralImage from "@page-components/products/cinema-hardware/media/vendor-neutral/"
import SecureImage from "@page-components/products/cinema-hardware/media/secure/"
import HEADERS from "@data/headers/"

const CinemaHardware = () => (
  <Layout
    getStartedButtons={["demo", "contact-sales"]}
    page="products/cinema-hardware"
    getStartedText="Learn more about switching to electronic DCP delivery.">
    <Background
      tagName="section"
      className="bg-cover bg-no-repeat bg-left center white"
      type="black-single"
      css={css`
        padding-top: 12rem;
        padding-bottom: 8rem;
      `}>
      <Navigation navigation={HEADERS.cinema_hardware} />
      <h1>Introducing the CSX</h1>
      <p className="large-p-tag max-width-2 mx-auto mt1 mb3">
        The CSX is the first and only API-driven broadband DCP distribution system powered by IBM Aspera, the most
        trusted file transfer technology in Hollywood.
      </p>
      <div className="relative mx-auto col-12 mt4 max-width-3">
        <HeaderImage />
      </div>
    </Background>
    <Feature
      title="Unparalleled Speeds"
      text="The CSX supports electronic DCP delivery of features, trailers, and adverts with a standard DSL, cable, or fibre internet connection. Powered by IBM Aspera for reliability, security and speed: delivering transfer rates up to 1Gbps."
      icon="arrows-white"
      image={<SpeedImage />}
    />
    <Feature
      title="Simple Plug-and-Play"
      reverse
      text="Intuitive 1-step setup process, the CSX connects in minutes to any brand of TMS, IMB, or standalone cinema server by providing a network ingest location across LAN."
      icon="play-white"
      image={<PlugAndPlayImage />}
    />
    <Feature
      title="Web-based Intuitive Design"
      text="While the CSX can be left alone without human interaction, the system can optionally be monitored and controlled through the CineSend Venues web portal, which provides system stats, queued downloads, and other features for both savvy projectionists and non-technical staff."
      icon="screen-white"
      image={<IntuitiveDesignImage />}
    />
    <Feature
      title="Open and Vendor-Neutral"
      reverse
      text="The CSX system is the first electronic delivery network that is open and accessible to any DCP vendor that wants to leverage the platform’s API."
      icon="vendor-white"
      image={<VendorNuetralImage />}
    />
    <Feature
      title="Stable and Secure"
      text="The CSX is an enterprise-grade 1U rack-mountable device that is rated to have a 10-year+ lifespan. Content is stored on an encrypted RAID array with field-swappable and upgradeable drives, and the entire platform is built with best-in-class security and redundancy."
      icon="lock-white"
      image={<SecureImage />}
    />
    <Grid
      columnWidth={4}
      items={[
        {
          title: "Live Event Streaming",
          text:
            "Connect an optional CSL live stream decoder to your CSX to add support for 2K and 4K live event cinema and eSports streaming. The CSL outputs to HDMI, DVI (video), and AES-3id (audio).",
          _id: 1,
          icon: "play-white"
        },
        {
          title: "Limit Download Speeds",
          text: `If you need to share your internet connection with other business systems or general office use, set up optional business hours/ time-of-day bandwidth caps to restrict CSX download speeds.`,
          _id: 2,
          icon: "network-white"
        },
        {
          title: "Integrated Firewall",
          text:
            "The CSX can be plugged directly into the internet. The device, its integrated storage, and devices on your LAN are protected with a robust firewall that’s pre-configured out of the box.",
          _id: 3,
          icon: "security-white"
        },
        {
          title: "Easy Setup",
          text:
            "Installation is as simple as connecting the unit to power, the internet, and your LAN or cinema server. Configure network settings through a step-by-step setup wizard with any device with wi-fi.",
          _id: 4,
          icon: "settings-white"
        },
        {
          title: "Support for USB Drives",
          text:
            "Optionally ingest DCP content onto your CSX through two USB3 ports on the front of the unit. You can also export DCPs from the CSX internal storage onto USB3 storage devices.",
          _id: 5,
          icon: "usb-white"
        },
        {
          title: "Automatic Updates",
          text:
            "We’re constantly improving the CSX firmware with new features and performance enhancements. Updates are pushed automatically in the background and are free forever.",
          _id: 6,
          icon: "campaign-wizard"
        }
      ]}
    />
  </Layout>
)

CinemaHardware.propTypes = {}

export default CinemaHardware
